html {
  background-color: #131369;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
:root {
  --primary: transparent;
  --secondary: cornflowerblue;
}

li {
  list-style: circle;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px !important;
}

.swiper-pagination-bullet {
  width: 49px !important;
  height: 15px !important;
  border-radius: 7.5px !important;
}

.swiper-pagination {
  position: relative !important;
  height: 60px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-who .swiper-pagination {
  height: 90px !important;
  align-content: end !important;
}
.swiper-reviews .swiper-pagination {
  margin-top: 0px !important;
}

@media (min-width: 500px) {
  .swiper-reviews .swiper-pagination {
    margin-top: 0px !important;
  }
}

.swiper-coach-mobile .swiper-pagination {
  height: 0px !important;
}

.swiper-coachs-desktop .swiper-pagination {
  transform: translateY(-30px) !important;
}

.swiper-reviews-mobile .swiper-pagination {
  transform: translateY(-50px) !important;
}

.shadow-fix {
  box-shadow: 0 1px 3px #ffffff;
}

.video-transition {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.faq-picture {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.transition-transform {
  transform: rotate(-20deg) translate(-65px, -40px);
}

.transition-transform-2 {
  transform: rotate(20deg) translate(-35px, -10px);
}

.flip-card {
  background-color: transparent;
  width: 430px;
  height: 250px;
  perspective: 1000px;
}

@media (min-width: 500px) {
  .flip-card {
    width: 430px;
    height: 300px !important;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-inner {
  transition: transform 0.5s;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.header-fixed {
  transition: all 0.5s;
}

.swiper-wrapper {
  align-items: flex-end;
}

.intervenants-desktop .swiper {
  margin: 0 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.collapse-card::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-element::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.collapse-card .scrollable-element {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-element {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.swiper-coachs-desktop .scrollable-element {
  overflow-y: scroll;
}
